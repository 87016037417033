<template>
  <el-table v-loading="loading" :data="data">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="Tên danh mục" prop="name">
      <template slot-scope="scope">
        <span>{{ scope.row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Ngày khởi tạo" prop="created_at">
      <template slot-scope="scope">
        <span>{{ scope.row.created_at }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Ngôn ngữ" width="250" align="center">
      <template slot-scope="scope">
        <el-row type="flex" align="middle" justify="center">
          <div>
            <img
              v-for="locale in locales"
              :key="locale.code"
              class="language"
              :class="{ inactive: !scope.row.translations || !scope.row.translations.find((t) => t.locale === locale.code)}"
              :src="locale.avatar_url"
              alt="href"
            />
          </div>
        </el-row>
      </template>
    </el-table-column>
    <el-table-column label="Thao tác" width="100" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Chỉnh sửa" placement="top">
          <el-button icon="el-icon-edit" type="primary" circle size="small" @click="handleUpdateCategory(scope.row)"></el-button>
        </el-tooltip>
        <el-tooltip content="Xóa" placement="top">
          <el-button icon="el-icon-delete" type="danger" circle size="small" @click="handleDeleteCategory(scope.row)"></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { deleteCategory } from '@/services/category'
export default {
  name: 'ListCategories',
  props: {
    data: Array,
    loading: Boolean,
    type: String
  },
  computed: {
    ...mapGetters(['constant']),
    locales() {
      return this.constant.constants.locales
    }
  },
  methods: {
    handleDeleteCategory(row) {
      this.$confirm('Bạn có chắc chắn muốn xóa danh mục này không?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(() => {
        const params = {
          id: row.id
        }
        deleteCategory(params, this.type).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: `Xóa danh mục thành công`
          })
          this.$emit('reload')
        })
      })
    },

    handleUpdateCategory(row) {
      this.$emit('update', row)
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin: 5px 0;
  &.language {
    width: 20px;
    height: 20px;
    border-radius: 0px;
    margin: 0 5px;
    &.inactive {
      filter: grayscale(100%);
    }
  }
}
</style>
